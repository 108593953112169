body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* statistics */
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.wrapper{
  width: 100%;
  max-width: 1200px;
  margin: auto;
  overflow-x: hidden;
  padding: 20px;
}

.custom-form{
  width: 500px;
}

.btn{
  margin: 10px 0;
}

.viewer{
  width: 100%;
  background-color: #e4e4e4;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
